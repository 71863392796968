import React from "react";
import { useTranslation } from "react-i18next";

import { redirectToExternalResource } from "../../helpers";

import "./index.css";

type PropsType = {
    title: string;
    description: string;
    link: string;
    img: string;
    id: string;
};

export function PortalCard({ id, title, description, link, img }: PropsType) {
    const { t } = useTranslation();
    const uuidEntity = localStorage.getItem("Uid_Entitie_User") as string;

    const uuidProdClientAuth1 = [
        "167f4186-3835-473d-a671-ed8b5a72f45b",
        "25c04501-8a29-44c3-95b4-7b6a7d5ba0a5",
        "717ff14a-cfc1-485b-b8e4-813800cac398",
        "fc62c60e-6c97-4b15-aeb1-574fb9a5ab6c",
    ];

    const IdProdClientAuth1 = [
        "Reporting-1",
        "Monitoring-1",
        "Installer-1",
        "Administration-console-1",
    ];

    const uuidProdClientAuth2 = ["61c50d51-0faa-41fa-b814-411742cdd794"];

    const IdProdClientAuth2 = ["Administration-console-1"];

    return (
        <React.Fragment>
            <div
                className="card__clz"
                style={{
                    pointerEvents:
                        (uuidProdClientAuth1.includes(uuidEntity) &&
                            IdProdClientAuth1.includes(id)) ||
                        (uuidProdClientAuth2.includes(uuidEntity) &&
                            IdProdClientAuth2.includes(id))
                            ? "none"
                            : "auto",
                    display:
                        (uuidProdClientAuth1.includes(uuidEntity) &&
                            IdProdClientAuth1.includes(id)) ||
                        (uuidProdClientAuth2.includes(uuidEntity) &&
                            IdProdClientAuth2.includes(id))
                            ? "none"
                            : "",
                }}
                onClick={() => {
                    redirectToExternalResource(link);
                }}
            >
                <div className="card-header__clz">
                    <img src={img} alt="rover" />
                </div>
                <div className="card-body__clz">
                    <h1 className="home-page-card-title__clz">{t(title)}</h1>
                    <span className="home-page-card-description__clz">
                        {t(description)}
                    </span>
                </div>
            </div>
        </React.Fragment>
    );
}
