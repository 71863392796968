import React from "react";
import { useTranslation } from "react-i18next";

import { PortalCard } from "./PortalCard";

import "./index.css";

export function Home() {
    const { t } = useTranslation();

    const cardsData: any = {
        firstContainer: [
            {
                id: "Reporting-1",
                title: "Reporting",
                img: "Reporting.png",
                description: "Statistics and KPIS",
                link: `${process.env.REACT_APP_LINK_URL_REPORTING}`,
            },
            {
                id: "Catalogue-1",
                title: "Catalogue",
                img: "Catalogue.png",
                description: "Card management",
                link: `${process.env.REACT_APP_LINK_URL_CATALOGUE}`,
            },
            {
                id: "Setting-1",
                title: "Setting",
                img: "Setting.png",
                description: "Application and device settings",
                link: `${process.env.REACT_APP_LINK_URL_SETTING}`,
            },
            {
                id: "Access-1",
                title: "Access",
                img: "Access.png",
                description: "User Management",
                link: `${process.env.REACT_APP_LINK_URL_ACCESS}`,
            },
        ],
        secondContainer: [
            {
                id: "Installer-1",
                title: "Installer",
                img: "Installer.png",
                description: "Application installer",
                link: `${process.env.REACT_APP_LINK_URL_INSTALLER}`,
            },
            {
                id: "Monitoring-1",
                title: "Monitoring",
                img: "Monitoring.png",
                description: "Park monitoring",
                link: "https://aures.provconnect.net/",
            },
            {
                id: "Administration-console-1",
                title: "Administration console",
                img: "Console.png",
                description: "Account and license management",
                link: `${process.env.REACT_APP_LINK_URL_CONSOLE_ADMINISTRATION}`,
            },
        ],
        // thirdContainer: [
        //     {
        //         title: "Wiki",
        //         img: "Wiki.png",
        //         description: "Wiki",
        //         link: "",
        //     },
        //     {
        //         title: "FQA",
        //         img: "FQA.png",
        //         description: "Frequently Asked Questions",
        //         link: "",
        //     },
        //     {
        //         title: "Technical support",
        //         img: "technicalSupport.png",
        //         description: "Technical assistance",
        //         link: "",
        //     },
        // ],
    };
    return (
        <React.Fragment>
            <div className="title__clz">
                <h1>
                    <span className="etk-title__clz">
                        {t("The ETK experience")}.
                    </span>
                    <span className="opacity-title__clz">
                        {" "}
                        {t("Do even more with ETK products and services")}
                    </span>
                </h1>
            </div>
            <div className="cards">
                <div className="container__clz d-flex">
                    {cardsData.firstContainer.map((el: any) => (
                        <PortalCard
                            id={el.id}
                            title={el.title}
                            description={el.description}
                            link={el.link}
                            img={el.img}
                        />
                    ))}
                </div>
                <div className="second-container__clz d-flex">
                    {cardsData.secondContainer.map((el: any) => (
                        <PortalCard
                            id={el.id}
                            title={el.title}
                            description={el.description}
                            link={el.link}
                            img={el.img}
                        />
                    ))}
                </div>
                {/* <div className="third-container__clz d-flex">
                    {cardsData.thirdContainer.map((el: any) => (
                        <PortalCard
                            title={el.title}
                            description={el.description}
                            link={el.link}
                            img={el.img}
                        />
                    ))}
                </div> */}
            </div>
        </React.Fragment>
    );
}
