import crypto from "crypto";

export function redirectToExternalResource(url: string) {
    window.open(url);
}

export async function logError(Source: any, data: any) {
    let fs = require("fs");
    let dir = "./";
    if (!fs.existsSync(dir)) {
        fs.mkdirSync(dir);
    }
    let madatetrace = new Date()
        .toISOString()
        .replace("T", " ")
        .substring(0, 19);
    let JourTrace = madatetrace.substring(0, 10);
    let heuretrace = madatetrace.substring(10, 25);
    let Reptrace = dir + "\\" + JourTrace.toString() + " LogServer.txt";
    let msg =
        "************************( " +
        Source +
        " )********************************" +
        "\r" +
        JourTrace +
        " " +
        heuretrace +
        "\r" +
        data.toString() +
        "\r" +
        "----------------------------------------------------------------------------------------" +
        "\r";
    fs.appendFile(Reptrace, msg, function (erreur: any) {
        if (erreur) return console.log("erreur");
    });
}

export function decryptWord(wordToDecrypt: string): string {
    let decryptedWord = wordToDecrypt;
    const key = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_SecurityKey}`), "utf8")
        .digest("hex")
        .substr(0, 32);
    const iv = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_InitVector}`), "utf8")
        .digest("hex")
        .substr(0, 16);
    const buff = Buffer.from(decryptedWord, "base64");
    decryptedWord = buff.toString("utf8");
    const decryptor = crypto.createDecipheriv(
        String("AES256"),
        // String(`${process.env.REACT_APP_Algorithm}`),
        key,
        iv
    );

    const decryptedData =
        decryptor.update(decryptedWord, "base64", "utf8") +
        decryptor.final("utf8");
    return decryptedData;
}

export function cryptWord(wordToCrypt: string): string {
    const key = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_SecurityKey}`), "utf8")
        .digest("hex")
        .substr(0, 32);
    const iv = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_InitVector}`), "utf8")
        .digest("hex")
        .substr(0, 16);

    const encryptor = crypto.createCipheriv(
        // String(`${process.env.REACT_APP_Algorithm}`),
        String("AES256"),
        key,
        iv
    );
    const aesEncrypted =
        encryptor.update(wordToCrypt, "utf8", "base64") +
        encryptor.final("base64");
    return Buffer.from(aesEncrypted).toString("base64");
}
