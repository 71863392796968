import React from "react";
import { Navigate } from "react-router-dom";

import { Home } from "@pages/Home";
import { SpinnerWrapper } from "@components/common/SpinnerWrapper";
import LoginAuthRoute from "./middleware/LoginAuthRoute";
import Login from "@pages/Home/Authentication/Login";
import { Callback } from "@pages/Home/Authentication/Callback";

const Layout = React.lazy(() => import("../components/Layout"));

export const routes = [
    {
        element: (
            <LoginAuthRoute>
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <Layout />
                </React.Suspense>
            </LoginAuthRoute>
        ),
        children: [
            { path: "/", element: <Navigate to="/home" /> },
            { path: "/home", element: <Home /> },
        ],
    },
    {
        element: <Login />,
        path: "login",
    },
    {
        element: <Callback />,
        path: "/Callback",
    },
    {
        path: "*",
        element: <Navigate to="/home" />,
    },
];
